/**
 * Tabs for example section
 */

const exampleTabs = () => {
  const tabButtons = document.querySelectorAll('.js-tab-button');
  const contents = document.querySelectorAll('.example__tab-content');

  if (!tabButtons || !contents) return
  for (let i = 0; i < tabButtons.length; i++) {
    tabButtons[i].addEventListener('click', () => {
      tabButtons.forEach(button => {
        button.classList.remove('active');
      });
      contents.forEach(content => {
        content.classList.remove('active');
      });
      tabButtons[i].classList.add('active');
      contents[i].classList.add('active');
    })
  }
}

export default exampleTabs