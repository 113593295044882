/**
 * Script for getting timestamp on form submit
 */

const getTimeStamp = () => {
  const button = document.querySelector('.js-form-button')
  const timeStampField = document.querySelector('.js-time-stamp') as HTMLInputElement

  if (! timeStampField || !button) return
  button.addEventListener('mouseover', () => {
    let date = new Date();
    let currentDay = ((date.getDate() >= 10) ? date.getDate() : '0' + date.getDate())
    let currentMonth = ((date.getMonth() + 1 >= 10) ? date.getMonth() + 1 : '0' + (date.getMonth() + 1))
    let currentYear = date.getFullYear()
    let currentHour = ((date.getHours() >= 10) ? date.getHours() : '0' + date.getHours())
    let currentMin = ((date.getMinutes() >= 10) ? date.getMinutes() : '0' + date.getMinutes())
    let currentSec = ((date.getSeconds() >= 10) ? date.getSeconds() : '0' + date.getSeconds())

    timeStampField.value = currentYear + '-' + currentMonth + '-' + currentDay + ' ' + currentHour + ':' + currentMin + ':' + currentSec
  })
}

export default getTimeStamp