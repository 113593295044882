/**
 * Hamburger menu
 */
const hamburger = () => {
  const icon = document.querySelector('.header__hamburger-menu');
  const menu = document.querySelector('.header__hamburger-nav');

  if (icon && menu) {
    icon.addEventListener('click', () => {
      icon.classList.toggle('active');
      menu.classList.toggle('active');
    });
  }
}

export default hamburger