/**
 * Script for unchecking checkbox on pageload
 */

const uncheck = () => {
  const checkbox = document.querySelector('input[type=checkbox]') as HTMLInputElement
   
  if (checkbox)
  checkbox.checked = false
}

export default uncheck