/**
 * Splide for faq section
 */

const faqSlider = () => {
  const slider = document.querySelector('.js-slider');
  const sliderPages = document.querySelector('.js-button-slider');
  const sliderPaginations = document.querySelectorAll('.faq__slider-button')

  if (!slider) return
  //@ts-ignore
  let sliderFlickity = new Flickity(slider, {
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
  });
  //@ts-ignore
}

export default faqSlider