/**
 * set radio button value on click
 */

const setValue = () => {
  const mvButton = document.querySelector('.js-set-value')
  const documentRadio = document.querySelector('input[value="資料請求"]') as HTMLInputElement
  const redirectLink = document.querySelector('.js-redirect') as HTMLInputElement

  if (!mvButton || !documentRadio) return
  mvButton.addEventListener('click', () => {
    documentRadio.checked = true
    redirectLink.value = `https://${window.location.hostname}/thanks-2.html`
  })
}

export default setValue