import exampleTabs from './components/exampleTabs'
import faqSlider from './components/flickity'
import test from './components/test'
import hamburger from './components/hamburger'
import form from './components/form'
import getTimeStamp from './components/getTimeStamp'
import uncheck from './components/uncheck'
import btt from './components/btt'
import redirect from './components/redirectLink'
import setValue from './components/setValue'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    test()
    faqSlider()
    exampleTabs()
    hamburger()
    // form()
    getTimeStamp()
    uncheck()
    btt()
    redirect()
    setValue()
  },
  false
)
