/**
 * set redirect link
 */

const redirect = () => {
  const radio = document.querySelectorAll('input[name=type]')
  const redirect = document.querySelector('.js-redirect') as HTMLInputElement

  if (!radio) return

  radio.forEach(radio => {
    radio.addEventListener('change', () => {
      //@ts-ignore
      if(radio.value === 'お問合せ') {
        redirect.value = `https://${window.location.hostname}/thanks-1.html`
      } else {
        redirect.value = `https://${window.location.hostname}/thanks-2.html`
      }
    })
  })
}

export default redirect