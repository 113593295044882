/**
 * Script for back to top
 */

const btt = () => {
  const btt = document.querySelector('.js-btt')

  if(!btt) return
  btt.addEventListener('click', () => {
    window.scrollTo(0, 0)
  })
}

export default btt
